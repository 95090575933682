import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/layout.js";
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ball from "../images/blog3/ball.webp";
import beach from "../images/blog3/beach.webp";
import bird from "../images/blog3/bird.webp";
import budgie from "../images/blog3/budgie.webp";
import cat from "../images/blog3/cat.webp";
import clam from "../images/blog3/clam.webp";
import clover from "../images/blog3/clover.webp";
import earth from "../images/blog3/earth.webp";
import fern from "../images/blog3/fern.webp";
import jasmine from "../images/blog3/jasmine.webp";
import jasmine2 from "../images/blog3/jasmine2.webp";
import lighthouse from "../images/blog3/lighthouse.webp";
import lilikoi from "../images/blog3/lilikoi.webp";
import lily from "../images/blog3/lily.webp";
import moon from "../images/blog3/moon.webp";
import nebula from "../images/blog3/nebula.webp";
import piplup from "../images/blog3/piplup.webp";
import piplup2 from "../images/blog3/piplup2.webp";
import plant from "../images/blog3/plant.webp";
import rose from "../images/blog3/rose.webp";
import saturn from "../images/blog3/saturn.webp";
import shrek from "../images/blog3/shrek.webp";
import vine from "../images/blog3/vine.webp";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="The (Pixel) Art of Animal Crossing Flags" keywords={['gatsby', 'application', 'react', 'animal', 'crossing', 'pixel', 'art']} mdxType="SEO" />
    <h1>{`The (Pixel) Art of Animal Crossing Flags`}</h1>
    <h2>{`May 23, 2021`}</h2>
    <p>{`In 2020, the virus overtook our streets and Animal Crossing overtook our hearts. I contributed to our gaming epidemic by designing island flags for strangers around the world.`}</p>
    <p>{`I’ve been creating pixel art since I was a kid, but my artwork never really went out into the world. So I enjoyed creating free personal patches of art for everyone. `}</p>
    <p>{`It all started when I created a flag for myself. I made an account on Reddit just to post an image of it. It blew up. My DMs were flooded with requests and I never said no.`}</p>
    <img height="auto" loading="lazy" src={lily} alt="Lily of The Valley" className="center" />
    <div className="caption">My personal island flag of a Lily of The Valley.</div>
    <p>{`I eventually stopped (which was possible because I never charged for these). Yet, I still get requests now and then. `}</p>
    <p>{`There were 4 big challenges in making these flags:`}</p>
    <ul>
      <li parentName="ul">{`Each flag had to be personal. So it not only had to have a unique meaningful design for the client, but it also had to be different from other flags I made. `}</li>
      <li parentName="ul">{`The game limits how many colors you can use.`}</li>
      <li parentName="ul">{`The artwork has to fit in a 32x32 pixel grid.`}</li>
      <li parentName="ul">{`The game tries to smooth out the pixel art to remove hard corners. This creates unintentional results, which sometimes forced me to rethink the designs.`}</li>
    </ul>
    <p>{`Anyways, here’s a collection of most of my art. Yipeeeee!!!`}</p>
    <img height="auto" loading="lazy" src={ball} alt="Ball in the sky" className="center" />
    <div className="caption">A sapphire marble gleaming in the night.</div>
    <img height="auto" loading="lazy" src={beach} alt="Flower" className="center" />
    <div className="caption">I loved drawing the foam in the water.</div>
    <img height="auto" loading="lazy" src={bird} alt="Birds flying" className="center" />
    <div className="caption">It's the bird from the Nintendo DS apps!</div>
    <img height="auto" loading="lazy" src={budgie} alt="Parakeets" className="center" />
    <div className="caption">The client wanted to show off her cute pets.</div>
    <img height="auto" loading="lazy" src={cat} alt="A cat butte" className="center" />
    <div className="caption">It's a cat butte. Look up butte.</div>
    <img height="auto" loading="lazy" src={clam} alt="Pearl in a clam" className="center" />
    <div className="caption">It took forever to get that clam shape right.</div>
    <img height="auto" loading="lazy" src={clover} alt="Clover crest" className="center" />
    <div className="caption">This crest was related to the client's late grandparents.</div>
    <img height="auto" loading="lazy" src={earth} alt="Earth" className="center" />
    <div className="caption">I loved using those neon colors.</div>
    <img height="auto" loading="lazy" src={fern} alt="Fern" className="center" />
    <div className="caption">For a person named Fern.</div>
    <img height="auto" loading="lazy" src={jasmine} alt="Flowers" className="center" />
    <div className="caption">Posing the flower took forever.</div>
    <img height="auto" loading="lazy" src={jasmine2} alt="A flag on an island" className="center" />
    <div className="caption">She loved it!</div>
    <img height="auto" loading="lazy" src={lighthouse} alt="A lighthouse on the coast" className="center" />
    <div className="caption">Very european.</div>
    <img height="auto" loading="lazy" src={lilikoi} alt="A lilikoi fruit" className="center" />
    <div className="caption">It's a lilikoi fruit! Took forever to get the seeds right.</div>
    <img height="auto" loading="lazy" src={moon} alt="A moon on the horizon" className="center" />
    <div className="caption">A beautiful flag for my beautiful girlfriend!</div>
    <img height="auto" loading="lazy" src={nebula} alt="Nebula" className="center" />
    <div className="caption">A nebula. Kinda looks like intestines.</div>
    <img height="auto" loading="lazy" src={piplup} alt="Piplup." className="center" />
    <div className="caption">The client's sibling loved piplup!</div>
    <img height="600px" loading="lazy" src={piplup2} alt="It's piplup again!" className="center" />
    <div className="caption">Yay!!!</div>
    <img height="auto" loading="lazy" src={plant} alt="A plant" className="center" />
    <div className="caption">Tried a new frame style here.</div>
    <img height="auto" loading="lazy" src={rose} alt="A field of golden roses" className="center" />
    <div className="caption">I was surprised at how dense this ended up.</div>
    <img height="auto" loading="lazy" src={saturn} alt="Saturn" className="center" />
    <div className="caption">A still Saturn.</div>
    <img height="auto" loading="lazy" src={shrek} alt="Shrek's outhouse" className="center" />
    <div className="caption">Shrek fans are inevitable.</div>
    <img height="auto" loading="lazy" src={vine} alt="Vines in the shape of a S" className="center" />
    <div className="caption">Inspired by the classic Gameboy screen.</div>
    <p>{`You're welcome to use any of these flags for your island! I haven't touched Animal Crossing in a long time, so I hope these codes still work:`}</p>
    <p>{`MA-2550-1514-0719`}</p>
    <p>{`DA-0916-3665-5805`}</p>
    <p>{`If you don't have Nintendo Online, you can recreate these flags because I posted screenshots of the editor itself! `}</p>
    <Link to="/" mdxType="Link">Home</Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      